import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d2cd01c6 = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _404205a6 = () => interopDefault(import('../pages/affiliates.vue' /* webpackChunkName: "pages/affiliates" */))
const _6ff0c2d4 = () => interopDefault(import('../pages/awards.vue' /* webpackChunkName: "pages/awards" */))
const _71779b89 = () => interopDefault(import('../pages/buy-solo-ads.vue' /* webpackChunkName: "pages/buy-solo-ads" */))
const _0519974c = () => interopDefault(import('../pages/buy-solo-ads/index.vue' /* webpackChunkName: "pages/buy-solo-ads/index" */))
const _15489bfa = () => interopDefault(import('../pages/buy-solo-ads/niche/_slug.vue' /* webpackChunkName: "pages/buy-solo-ads/niche/_slug" */))
const _01eb8b81 = () => interopDefault(import('../pages/dev-login.vue' /* webpackChunkName: "pages/dev-login" */))
const _53d8e758 = () => interopDefault(import('../pages/filter.vue' /* webpackChunkName: "pages/filter" */))
const _56ab6c74 = () => interopDefault(import('../pages/forgot/index.vue' /* webpackChunkName: "pages/forgot/index" */))
const _190dce1e = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _2ad166b4 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _4f17786c = () => interopDefault(import('../pages/forum/comment/_id.vue' /* webpackChunkName: "pages/forum/comment/_id" */))
const _0926b792 = () => interopDefault(import('../pages/forum/_section/index.vue' /* webpackChunkName: "pages/forum/_section/index" */))
const _27e9e3ac = () => interopDefault(import('../pages/forum/_section/_idSlug.vue' /* webpackChunkName: "pages/forum/_section/_idSlug" */))
const _402090a1 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _fbe74b38 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _9e30573e = () => interopDefault(import('../pages/help/support.vue' /* webpackChunkName: "pages/help/support" */))
const _51242e92 = () => interopDefault(import('../pages/help/a/_plink.vue' /* webpackChunkName: "pages/help/a/_plink" */))
const _2b84d56f = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _cfa9ba84 = () => interopDefault(import('../pages/help/_unregistered/_plink.vue' /* webpackChunkName: "pages/help/_unregistered/_plink" */))
const _12c31739 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2fe57bea = () => interopDefault(import('../pages/logo.vue' /* webpackChunkName: "pages/logo" */))
const _1d46a078 = () => interopDefault(import('../pages/logos.vue' /* webpackChunkName: "pages/logos" */))
const _0b995f4f = () => interopDefault(import('../pages/prime.vue' /* webpackChunkName: "pages/prime" */))
const _a0d16988 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _939861a4 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _f3dc2cf0 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _33a707f3 = () => interopDefault(import('../pages/search/_unregistered.vue' /* webpackChunkName: "pages/search/_unregistered" */))
const _dc0c8610 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _102356b6 = () => interopDefault(import('../pages/solo-ads/index.vue' /* webpackChunkName: "pages/solo-ads/index" */))
const _eccc1750 = () => interopDefault(import('../pages/solodeals.vue' /* webpackChunkName: "pages/solodeals" */))
const _33784e4a = () => interopDefault(import('../pages/solodeals/index.vue' /* webpackChunkName: "pages/solodeals/index" */))
const _d09d645c = () => interopDefault(import('../pages/solodeals/price.vue' /* webpackChunkName: "pages/solodeals/price" */))
const _e413c822 = () => interopDefault(import('../pages/solodeals/search.vue' /* webpackChunkName: "pages/solodeals/search" */))
const _48e9e308 = () => interopDefault(import('../pages/solodeals/_post.vue' /* webpackChunkName: "pages/solodeals/_post" */))
const _2ad8f572 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _6c9d12bf = () => interopDefault(import('../pages/test-apple.vue' /* webpackChunkName: "pages/test-apple" */))
const _127a5e28 = () => interopDefault(import('../pages/test-cases.vue' /* webpackChunkName: "pages/test-cases" */))
const _12bb955b = () => interopDefault(import('../pages/test-cases/page1.vue' /* webpackChunkName: "pages/test-cases/page1" */))
const _12c9acdc = () => interopDefault(import('../pages/test-cases/page2.vue' /* webpackChunkName: "pages/test-cases/page2" */))
const _a959adae = () => interopDefault(import('../pages/test-owl.vue' /* webpackChunkName: "pages/test-owl" */))
const _67067036 = () => interopDefault(import('../pages/udimi-reviews-video/index.vue' /* webpackChunkName: "pages/udimi-reviews-video/index" */))
const _0cb7e436 = () => interopDefault(import('../pages/competition/hall-of-fame.vue' /* webpackChunkName: "pages/competition/hall-of-fame" */))
const _93cba592 = () => interopDefault(import('../pages/notifications/instantunsubscribe.vue' /* webpackChunkName: "pages/notifications/instantunsubscribe" */))
const _1db05f6c = () => interopDefault(import('../pages/notifications/settings.vue' /* webpackChunkName: "pages/notifications/settings" */))
const _66915ffa = () => interopDefault(import('../pages/profile/custom-route/recommends.vue' /* webpackChunkName: "pages/profile/custom-route/recommends" */))
const _491b7422 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9089db4c = () => interopDefault(import('../pages/competition/movers-and-shakers/_month.vue' /* webpackChunkName: "pages/competition/movers-and-shakers/_month" */))
const _5345134d = () => interopDefault(import('../pages/articles/_plink.vue' /* webpackChunkName: "pages/articles/_plink" */))
const _42f5f61a = () => interopDefault(import('../pages/ratings/_month.vue' /* webpackChunkName: "pages/ratings/_month" */))
const _e7ad4c0a = () => interopDefault(import('../pages/restore/_token.vue' /* webpackChunkName: "pages/restore/_token" */))
const _198d8080 = () => interopDefault(import('../pages/reviews/_permalink.vue' /* webpackChunkName: "pages/reviews/_permalink" */))
const _3a12f321 = () => interopDefault(import('../pages/share/_uid.vue' /* webpackChunkName: "pages/share/_uid" */))
const _af5914b0 = () => interopDefault(import('../pages/solo-ads/_plink.vue' /* webpackChunkName: "pages/solo-ads/_plink" */))
const _04e438a0 = () => interopDefault(import('../pages/token/_token.vue' /* webpackChunkName: "pages/token/_token" */))
const _353e0c81 = () => interopDefault(import('../pages/udimi-reviews-video/_page.vue' /* webpackChunkName: "pages/udimi-reviews-video/_page" */))
const _2bf91ab8 = () => interopDefault(import('../pages/solo/_uid/_anySubRoute.vue' /* webpackChunkName: "pages/solo/_uid/_anySubRoute" */))
const _3989e20d = () => interopDefault(import('../pages/_unregistered/index.vue' /* webpackChunkName: "pages/_unregistered/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _d2cd01c6,
    name: "affiliate"
  }, {
    path: "/affiliates",
    component: _404205a6,
    name: "affiliates"
  }, {
    path: "/awards",
    component: _6ff0c2d4,
    name: "awards"
  }, {
    path: "/buy-solo-ads",
    component: _71779b89,
    children: [{
      path: "",
      component: _0519974c,
      name: "buy-solo-ads"
    }, {
      path: "niche/:slug?",
      component: _15489bfa,
      name: "buy-solo-ads-niche-slug"
    }]
  }, {
    path: "/dev-login",
    component: _01eb8b81,
    name: "dev-login"
  }, {
    path: "/filter",
    component: _53d8e758,
    name: "filter"
  }, {
    path: "/forgot",
    component: _56ab6c74,
    name: "forgot"
  }, {
    path: "/forum",
    component: _190dce1e,
    children: [{
      path: "",
      component: _2ad166b4,
      name: "forum"
    }, {
      path: "comment/:id?",
      component: _4f17786c,
      name: "forum-comment-id"
    }, {
      path: ":section",
      component: _0926b792,
      name: "forum-section"
    }, {
      path: ":section/:idSlug",
      component: _27e9e3ac,
      name: "forum-section-idSlug"
    }]
  }, {
    path: "/help",
    component: _402090a1,
    children: [{
      path: "",
      component: _fbe74b38,
      name: "help"
    }, {
      path: "support",
      component: _9e30573e,
      name: "help-support"
    }, {
      path: "a/:plink?",
      component: _51242e92,
      name: "help-a-plink"
    }, {
      path: ":category",
      component: _2b84d56f,
      name: "help-category"
    }, {
      path: ":unregistered/:plink?",
      component: _cfa9ba84,
      name: "help-unregistered-plink"
    }]
  }, {
    path: "/login",
    component: _12c31739,
    name: "login"
  }, {
    path: "/logo",
    component: _2fe57bea,
    name: "logo"
  }, {
    path: "/logos",
    component: _1d46a078,
    name: "logos"
  }, {
    path: "/prime",
    component: _0b995f4f,
    name: "prime"
  }, {
    path: "/profile",
    component: _a0d16988,
    name: "profile"
  }, {
    path: "/reviews",
    component: _939861a4,
    name: "reviews"
  }, {
    path: "/search",
    component: _f3dc2cf0,
    name: "search",
    children: [{
      path: ":unregistered?",
      component: _33a707f3,
      name: "search-unregistered"
    }]
  }, {
    path: "/signup",
    component: _dc0c8610,
    name: "signup"
  }, {
    path: "/solo-ads",
    component: _102356b6,
    name: "solo-ads"
  }, {
    path: "/solodeals",
    component: _eccc1750,
    children: [{
      path: "",
      component: _33784e4a,
      name: "solodeals"
    }, {
      path: "price",
      component: _d09d645c,
      name: "solodeals-price"
    }, {
      path: "search",
      component: _e413c822,
      name: "solodeals-search"
    }, {
      path: ":post",
      component: _48e9e308,
      name: "solodeals-post"
    }]
  }, {
    path: "/test",
    component: _2ad8f572,
    name: "test"
  }, {
    path: "/test-apple",
    component: _6c9d12bf,
    name: "test-apple"
  }, {
    path: "/test-cases",
    component: _127a5e28,
    name: "test-cases",
    children: [{
      path: "page1",
      component: _12bb955b,
      name: "test-cases-page1"
    }, {
      path: "page2",
      component: _12c9acdc,
      name: "test-cases-page2"
    }]
  }, {
    path: "/test-owl",
    component: _a959adae,
    name: "test-owl"
  }, {
    path: "/udimi-reviews-video",
    component: _67067036,
    name: "udimi-reviews-video"
  }, {
    path: "/competition/hall-of-fame",
    component: _0cb7e436,
    name: "competition-hall-of-fame"
  }, {
    path: "/notifications/instantunsubscribe",
    component: _93cba592,
    name: "notifications-instantunsubscribe"
  }, {
    path: "/notifications/settings",
    component: _1db05f6c,
    name: "notifications-settings"
  }, {
    path: "/profile/custom-route/recommends",
    component: _66915ffa,
    name: "profile-custom-route-recommends"
  }, {
    path: "/",
    component: _491b7422,
    name: "index"
  }, {
    path: "/competition/movers-and-shakers/:month?",
    component: _9089db4c,
    name: "competition-movers-and-shakers-month"
  }, {
    path: "/articles/:plink?",
    component: _5345134d,
    name: "articles-plink"
  }, {
    path: "/ratings/:month?",
    component: _42f5f61a,
    name: "ratings-month"
  }, {
    path: "/restore/:token?",
    component: _e7ad4c0a,
    name: "restore-token"
  }, {
    path: "/reviews/:permalink",
    component: _198d8080,
    name: "reviews-permalink"
  }, {
    path: "/share/:uid?",
    component: _3a12f321,
    name: "share-uid"
  }, {
    path: "/solo-ads/:plink?",
    component: _af5914b0,
    name: "solo-ads-plink"
  }, {
    path: "/token/:token?",
    component: _04e438a0,
    name: "token-token"
  }, {
    path: "/udimi-reviews-video/:page?",
    component: _353e0c81,
    name: "udimi-reviews-video-page"
  }, {
    path: "/solo/:uid?/:anySubRoute?",
    component: _2bf91ab8,
    name: "solo-uid-anySubRoute"
  }, {
    path: "/:unregistered",
    component: _3989e20d,
    name: "unregistered"
  }, {
    path: "/u/:hash",
    component: _93cba592,
    name: "u-hash"
  }, {
    path: "/u/settings/:hash",
    component: _1db05f6c,
    name: "u-settings-hash"
  }, {
    path: "/a/:uid",
    component: _491b7422,
    name: "affiliate-regular"
  }, {
    path: "/prime/:uid([a-zA-Z0-9]{5})",
    component: _491b7422,
    name: "affiliate-prime"
  }, {
    path: "/p/:uid_profile",
    component: _a0d16988,
    name: "profile-index"
  }, {
    path: "/p/:uid_profile/recommends",
    component: _66915ffa,
    name: "profile-recommends"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
