export const state = () => ({
    timeDiff: 0,
    niches: [],
    reviewsCount: 0,
    videoReviewsCount: 0,
    helpMenu: []
})

export const mutations = {
    setTimeDiff (state, payload) {
        state.timeDiff = (new Date()).getTime() - (new Date(payload)).getTime()
    },
    setNiches (state, payload) {
        state.niches = payload || []
    },
    setReviewsCount (state, payload) {
        state.reviewsCount = payload || 0
    },
    setVideoReviewsCount (state, payload) {
        state.videoReviewsCount = payload || 0
    },
    setHelpMenu (state, payload) {
        state.helpMenu = payload
    }
}

export const actions = {}
