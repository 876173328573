//
//
//
//
//
//
//
//
//
//
//

import LayMobileFooter from '~/components/layouts/LayMobileFooter'
import LayMobileHeader from '~/components/layouts/LayMobileHeader'
import LayMobileMenu from '~/components/layouts/LayMobileMenu'

export default {
    name: 'MobileLayout',
    components: {
        LayMobileMenu,
        LayMobileFooter,
        LayMobileHeader
    },
    data: () => ({
        isMenu: false
    }),
    computed: {
        isHeaderFixed () {
            return ['help', 'help-category', 'help-a-plink', 'reviews-permalink', 'niche-name', 'profile-index', 'profile-recommends', 'solodeals-post'].includes(this.$route.name) ||
                this.$route.path.startsWith('/forum') || this.isBlogPage
        },
        isNoFooter () {
            return ['help', 'help-category', 'help-a-plink', 'reviews-permalink', 'reviews'].includes(this.$route.name) ||
                this.$route.path.startsWith('/forum') || this.isBlogPage
        },
        isBlogPage () {
            return ['solo-ads', 'solo-ads-plink'].includes(this.$route.name)
        }
    },
    methods: {
        menuToggle (val) {
            this.isMenu = val
        }
    }
}
