export const keysToCamel = function (obj) {
    const toCamel = (str) => {
        return str.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '')
        })
    }
    if (obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function') {
        const n = {}

        Object.keys(obj)
            .forEach((k) => {
                n[toCamel(k)] = keysToCamel(obj[k])
            })

        return n
    } else if (Array.isArray(obj)) {
        return obj.map((i) => {
            return keysToCamel(i)
        })
    }

    return obj
}

export const addHighlight = (comment) => {
    const re = /(((a|one|two|three|four|five|six|seven|eight|nine|ten|few|couple|couple of|many|frontend|first|second|third|fourth|fifth|got me|got me a|direct solo|i got|got sales|some sales|multiple sales|several sales|a lot of sales|lot of sales|\d)+ +)*(sale[s]?)|affiliate commission|got sales|some sales|multiple sales|several sales|a lot of sales|lot of sales)/i
    return comment.replace(re, '<span class="m-sale">$1</span>')
}

export const nl2br = (str, isXhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return ''
    }
    const breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export const getBaseDomain = function (host) {
    const parts = host.split('.')
    while (parts.length > 2) {
        parts.shift()
    }
    return parts.join('.')
}

export const buildQueryString = (params, namespace) => {
    let qs = namespace ? '' : '?'
    for (const k in params) {
        if (!Object.prototype.hasOwnProperty.call(params, k)) {
            continue
        }
        const val = params[k]
        if (typeof val === 'object') {
            qs += buildQueryString(val, k)
        } else if (val !== null && typeof val !== 'undefined') {
            qs += (namespace ? `${namespace}[${k}]` : k) + '=' + val + '&'
        }
    }
    return namespace ? qs : qs.replace(/&$/, '')
}

export const findTag = (el, tags = ['input']) => {
    const neededTag = tags.find(tag => el.querySelector(tag))
    return el.querySelector(neededTag)
}

export const measureEl = (el, fn) => {
    const pV = el.style.visibility
    const pP = el.style.position

    el.style.visibility = 'hidden'
    el.style.position = 'absolute'

    document.body.appendChild(el)
    const result = fn(el)
    el.parentNode.removeChild(el)

    el.style.visibility = pV
    el.style.position = pP
    return result
}

export const randomIntFromInterval = (min, max) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function debounce (fn, delay) {
    let timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        const args = arguments
        const that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

export function formatDuration (value = 0) {
    const currentMinute = parseInt(value / 60) % 60
    const currentSecondsLong = value % 60
    const currentSeconds = currentSecondsLong.toFixed()

    return `${currentMinute < 10 ? `${currentMinute}` : currentMinute}:${
        currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds
    }`
}

export const stringPluralize = (count, str = '', suffix = 's', includeCount = true) => {
    let res = `${str}${+count === 1 ? '' : suffix}`
    if (includeCount) {
        res = `${count} ${res}`
    }
    return res
}

export const getErrorStatusCode = (error) => {
    return +error?.response?.status || +error?.response?.statusCode || +error?.status || +error?.statusCode || 0
}

export const queryPageFormatted = (page) => {
    return isNaN(+page) ? 1 : (+page || 1)
}

export const blurPrivacyFullname = (fullname) => {
    return `${fullname.split(' ')[0]} ***`
}

export const simpleTextMarkdown = (text) => {
    return text
        ? text
            .replace(/\*\*(.*?)\*\*/gi, (_, $2) => `<b>${$2}</b>`)
            .replace(/\*(.*?)\*/gi, (_, $2) => `<i>${$2}</i>`)
            .replace(/_(.*?)_/gi, (_, $2) => `<u>${$2}</u>`)
        : text
}

export const getDomainFromUrl = (url) => {
    /* eslint-disable no-useless-escape */
    let result
    let match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)
    if (match) {
        result = match[1]
        match = result.match(/^[^\.]+\.(.+\..+)$/)
        if (match) {
            result = match[0]
        }
    }
    /* eslint-enable no-useless-escape */
    return result
}

export const linkify = (text, allowExternalLink = false) => {
    if (text) {
        if (!text.includes('data-is-search="true"')) {
            text = text.replace(
                // eslint-disable-next-line
                /((href|src)?=?['"]?)?(((https?:\/\/)|(\bwww\.))(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\]};:'".,<>?«»“”‘’]))/gi,
                function (url, _p1, _p2, _p3, offset) {
                    let fullUrl = url
                    const trimmed = fullUrl.trim()

                    const domain = getDomainFromUrl(trimmed) || ''
                    const domainParts = domain.split('.')
                    const mainDomain = domainParts.length > 2 ? domainParts.slice(-2).join('.') : domain

                    const siteHost = process.env.SITE_HOST || (process.client && window.$nuxt?.$config?.siteHost) || 'udimi.com'
                    const siteHostParts = siteHost.split('.')
                    const mainDomainSiteHost = siteHostParts.length > 2 ? siteHostParts.slice(-2).join('.') : siteHost

                    if (mainDomain === mainDomainSiteHost || allowExternalLink) {
                        if (trimmed.indexOf('href') === 0 || trimmed.indexOf('src') === 0) {
                            return fullUrl
                        }
                        if (offset?.match(/(?:https?:\/\/)/gi)) {
                            fullUrl = fullUrl.replace(/(?:https?:\/\/)/gi, 'https://')
                        }
                        if (!fullUrl.match('^https?://')) {
                            fullUrl = 'https://' + fullUrl
                        }
                        return `<a href=${fullUrl} target="_self">${url}</a>`
                    } else {
                        return url
                    }
                }
            )
        }
        return text
    }
    return text
}

export const getResponseTime = (sec) => {
    return sec < 60
        ? stringPluralize(Math.round(sec), 'second')
        : sec < 60 * 60
            ? stringPluralize(Math.round(sec / 60), 'minute')
            : stringPluralize(Math.round(sec / 3600), 'hour')
}

export const mobileTooltipParams = {
    autoHide: false,
    delay: { show: 0, hide: 0 },
    trigger: 'click focus'
}

export const paginationMetaLinks = (metaInfo, app) => {
    if (!metaInfo?.totalPages) {
        return []
    }

    const page = app.$route?.query?.page || 1
    const queryPrev = Object.assign({}, app.$route?.query, { page: +page - 1 })
    const queryNext = Object.assign({}, app.$route?.query, { page: +page + 1 })

    return [
        (+page !== 1
            ? { rel: 'prev', hid: 'prev', href: `${app.$config.siteProtocol}://${app.$config.siteHost}${app.$route.path}${app.$api.buildQueryString(queryPrev)}` }
            : null),
        (+page !== +metaInfo.totalPages)
            ? { rel: 'next', hid: 'next', href: `${app.$config.siteProtocol}://${app.$config.siteHost}${app.$route.path}${app.$api.buildQueryString(queryNext)}` }
            : null
    ].filter(link => !!link)
}

export const formatUserRating = (ratings, isSeller = true) => {
    const rating = isSeller ? (ratings?.seller || {}) : (ratings?.buyer || {})
    return +rating.starsRateAvg
        ? rating.starsRateAvg + (rating.all ? ` (${rating.all})` : '')
        : 0
}
